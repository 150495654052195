import React, { useContext } from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"

import Layout from "../components/layouts"
import CollectionDetails from "../components/ProductGrid/CollectionDetails"
import GridContainer, {
  GridBundleContainer,
} from "../components/ProductGrid/GridContainer"
import SEO from "../components/seo"
import { PersonalizationContext } from "../context/personalization-context"
import {
  handleUrlPrefix,
  simpleWordTranslate,
} from "../utils/translations-util"
import { useTranslationContext } from "../context/TranslationContext"

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.almostWhite};
  padding-bottom: 50px;
  padding-top: 60px;
`

const CategoryGridTemplate = ({ data }) => {
  const {
    contentfulCategory: {
      name,
      metaImage,
      collections,
      description,
      slug,
      seoTitle,
      seoDescription,
    },
  } = data

  const { i18n } = useTranslation()
  const { translationContext } = useTranslationContext()
  const { gender, changeGender } = useContext(PersonalizationContext)

  return (
    <Layout pullUp almostWhite>
      <SEO
        title={seoTitle || name}
        metaImage={metaImage && metaImage.file.url}
        canonicals={translationContext.canonicals}
        description={seoDescription?.seoDescription || description.description}
      />
      {name === "Exclusives" ? (
        <Container>
          <CollectionDetails name={name} description={description} />
          <GridBundleContainer
            catSlug={slug}
            gender={gender}
            name={name}
            bundle={collections}
          />
        </Container>
      ) : (
        collections.map((collection) => {
          const hasGender = collection.products.find((p) =>
            p.internal.type === "ContentfulBeddingBundle"
              ? p.duvetProduct.imagePacks.length > 1
              : p.imagePacks.length > 1
          )

          let collectionPath = handleUrlPrefix(
            i18n.language,
            `${collection.category[0].slug}/${collection.slug}`
          )

          if (collection.productPageType === "Standalone") {
            collectionPath = `${handleUrlPrefix(
              i18n.language,
              `${simpleWordTranslate(i18n.language, "products")}`
            )}`
          }

          return (
            <Container key={collection.id}>
              <CollectionDetails
                name={collection.name}
                description={collection.description}
                changeGender={changeGender}
                hasGender={hasGender}
                gender={gender}
              />
              <GridContainer
                hasGender={hasGender}
                gender={gender}
                name={collection.name}
                collection={collection}
                collectionPath={collectionPath}
                products={collection.products}
              />
            </Container>
          )
        })
      )}
    </Layout>
  )
}

export default CategoryGridTemplate

export const query = graphql`
  query ($slug: String!, $locale: String) {
    contentfulCategory(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      name
      slug
      node_locale
      seoTitle
      seoDescription {
        seoDescription
      }
      metaImage {
        file {
          url
        }
      }
      description {
        description
        childMarkdownRemark {
          html
        }
      }
      collections {
        id
        name
        slug
        productPageType
        category {
          slug
        }
        description {
          description
          childMarkdownRemark {
            html
          }
        }
        ...CollectionProducts
      }
    }
  }
`
